import { SourceView, SourceViewListing } from '@/types';

import { AskDocInteractionType } from '../../ask-bluej/streaming/doc/shared';
import { QuestionInteractionType } from '../../ask-bluej/streaming/threaded/shared';
import { FaqInteractionContext, RestrictedSourceInteractionContext } from '../../thread/types';
import { ChatContext, EventTrackFn, ThreadContext } from './types';

type QuestionAskedProps = {
  chatId: string;
  threadId: string;
  answerStrategy: string;
  question: string;
  interactionContext: QuestionInteractionType | null;
};

export function questionAsked(track: EventTrackFn) {
  return function({ chatId, threadId, answerStrategy, question, interactionContext }: QuestionAskedProps) {
    track({
      action: 'questionSent',
      properties: {
        chatId,
        threadId,
        answerStrategy,
        characterCount: question.length,
        interactionContext
      }
    });
  }
}

export function threadCreated(track: EventTrackFn) {
  return function(threadId: string) {
    track({
      action: 'threadCreated',
      properties: {
        threadId
      }
    });
  }
}

export function threadDeleted(track: EventTrackFn) {
  return function(threadId: string) {
    track({
      action: 'threadDeleted',
      properties: {
        threadId
      }
    });
  }
}

export function threadViewed(track: EventTrackFn) {
  return function(threadId: string) {
    track({
      action: 'threadViewed',
      properties: {
        threadId
      }
    });
  }
}

export function threadRenamed(track: EventTrackFn) {
  return function(threadId: string) {
    return track({
      action: 'threadRenamed',
      properties: {
        threadId
      }
    });
  }
}

export function questionFeedback(track: EventTrackFn, context: ChatContext) {
  return function(feedback: {
    type: 'agree' | 'disagree';
    comment: string;
  }) {
    track({
      action: feedback.type === 'agree' ? 'responseLiked' : 'responseDisliked',
      properties: {
        ...context,
        comment: feedback.comment
      }
    });
  }
}

export function questionFeedbackClicked(track: EventTrackFn, context: ChatContext) {
  return function(feedback: {
    type: 'agree' | 'disagree';
  }) {
    track({
      action: feedback.type === 'agree' ? 'responseLikeClicked' : 'responseDislikeClicked',
      properties: {
        ...context
      }
    });
  }
}

export function shareEvent(track: EventTrackFn, action: 'responseCopyClicked' | 'responsePrintClicked', context: ChatContext) {
  return function() {
    track({
      action,
      properties: {
        ...context
      }
    });
  }
}

type SourceLinkClickedProps = {
  sourceType: SourceView['badge']; // Used to be displayType... confusing, since source.type is the top-level content type
  contentType?: string;
  answerStatus?: 'complete' | 'in-progress';
  restricted?: boolean;
  referredUrl?: string;
  sourceTitle: string;
  additionalSources?: boolean;
};


export function sourceLinkClicked(track: EventTrackFn, context: ChatContext, action = 'sourceLinkClicked') {
  return function({ sourceTitle, sourceType, contentType, answerStatus = 'complete', restricted = false, referredUrl, additionalSources }: SourceLinkClickedProps) {
    track({
      action,
      properties: {
        ...context,
        sourceTitle,
        sourceType,
        sourceTag: contentType,
        answerStatus,
        restricted,
        referredUrl,
        additionalSources
      }
    })
  }
}

type SourceNavigationClickedProps = {
  sourceType: SourceView['type'];
  additionalSources: boolean;
};

export function sourceNavigationClicked(track: EventTrackFn, context: ChatContext) {
  return function({ sourceType, additionalSources }: SourceNavigationClickedProps) {
    track({
      action: 'sourceNavigationClicked',
      properties: {
        ...context,
        sourceType,
        additionalSources
      }
    })
  }
}

type SourceViewedProps = {
  sourceType: SourceView['type'];
  contentType?: string;
  sourceTitle: string;
  additionalSources: boolean;
};

export function sourceViewed(track: EventTrackFn, context: ThreadContext) {
  return function({ sourceTitle, sourceType, contentType, additionalSources }: SourceViewedProps) {
    track({
      action: 'sourceViewed',
      properties: {
        ...context,
        sourceTitle,
        sourceType,
        sourceTag: contentType,
        additionalSources
      }
    })
  }
}

type SourceDetailsToggledProps = {
  sourceType: SourceView['type'];
  additionalSources: boolean;
};

export function createSourceDetailsToggledEvent(track: EventTrackFn, action: 'sourceDetailsToggledOn' | 'sourceDetailsToggledOff', context: ChatContext) {
  return function({ sourceType, additionalSources }: SourceDetailsToggledProps) {
    track({
      action,
      properties: {
        ...context,
        sourceType,
        additionalSources
      }
    })
  }
}

type ErrorTriggeredProps = {
  threadId: string;
  answerStrategy: string;
};

export function errorTriggered(track: EventTrackFn) {
  return function(props: ErrorTriggeredProps) {
    track({
      action: 'errorTriggered',
      properties: {
        ...props
      }
    });
  }
}

export function tryAgainClicked(track: EventTrackFn, context: ThreadContext) {
  return function() {
    track({
      action: 'tryAgainClicked',
      properties: {
        ...context
      }
    });
  }
}

type UnableToAnswerReceivedProps = {
  threadId: string;
  chatId: string;
  answerStrategy: string;
};

export function unableToAnswerReceived(track: EventTrackFn) {
  return function(props: UnableToAnswerReceivedProps) {
    track({
      action: 'unableToAnswerReceived',
      properties: {
        ...props
      }
    })
  }
}

export function faqClicked(track: EventTrackFn) {
  return function(interactionContext: FaqInteractionContext) {
    track({
      action: 'faqClicked',
      properties: {
        interactionContext
      }
    })
  }
}

export function logoClicked(track: EventTrackFn) {
  return function() {
    track({
      action: 'logoClicked'
    })
  }
}

export function logoutClicked(track: EventTrackFn) {
  return function() {
    track({
      action: 'logoutClicked'
    })
  }
}

export function newThreadClicked(track: EventTrackFn) {
  return function() {
    track({
      action: 'newThreadClicked'
    })
  }
}

export function sourcePrintClicked(track: EventTrackFn, context: ChatContext) {
  return function({ sourceType, sourceTitle, additionalSources }: {
    sourceType: SourceView['type'],
    sourceTitle: string,
    additionalSources?: boolean
  }) {
    track({
      action: 'sourcePrintClicked',
      properties: {
        ...context,
        sourceType,
        sourceTitle,
        additionalSources
      }
    })
  }
}

export function sourceOpenInNewTabClicked(track: EventTrackFn, context: ChatContext) {
  return function({ sourceType, sourceTitle, additionalSources }: {
    sourceType: SourceView['type'],
    sourceTitle: string,
    additionalSources: boolean
  }) {
    track({
      action: 'sourceOpenInNewTabClicked',
      properties: {
        ...context,
        sourceType,
        sourceTitle,
        additionalSources
      }
    })
  }
}

export function questionDeleted(track: EventTrackFn, context: ChatContext) {
  return function() {
    track({
      action: 'questionDeleted',
      properties: {
        ...context
      }
    });
  }
}

export function questionDeleteInitiated(track: EventTrackFn, context: ChatContext) {
  return function() {
    track({
      action: 'questionDeleteInitiated',
      properties: {
        ...context
      }
    });
  }
}

export function stopGeneratingClicked(track: EventTrackFn, context: ChatContext) {
  return function() {
    track({
      action: 'stopGeneratingClicked',
      properties: {
        ...context
      }
    })
  }
}

export function userNPSSurvey(track: EventTrackFn) {
  return function(score: number, comment: string, email: string, name: string) {
    track({
      action: 'userNPSSurvey',
      properties: {
        score,
        comment,
        email,
        name
      }
    });
  }
}

export function npsModalClosed(track: EventTrackFn) {
  return function() {
    track({
      action: 'npsModalClosed'
    })
  }
}

type AnswerGenerationProps = {
  threadId: string;
  chatId: string;
  answerStrategy: string;
  elapsedTime: number;
};

export function answerGenerationStarted(track: EventTrackFn) {
  return function({ threadId, chatId, answerStrategy, elapsedTime }: AnswerGenerationProps) {
    track({
      action: 'answerGenerationStarted',
      properties: {
        threadId,
        chatId,
        answerStrategy,
        elapsedTime
      }
    })
  }
}

type AnswerGenerationEndedProps = {
  threadId: string;
  chatId: string;
  answerStrategy: string;
  elapsedTime: number;
  characterCount: number;
  reason: 'aborted' | 'failed' | 'completed';
  sources: SourceViewListing[]
};

function getSourcesCounts(sources: SourceViewListing[]) {
  return sources.reduce((counts: Record<string, number>, source) => {
    counts.totalSources++;
    if (source.badge === 'commentary') {
      counts[`${source.badge}Sources`]++;
    } else {
      if (source.type === 'curator_case') {
        counts.caseSources++;
        return counts;
      }

      if (source.type === 'curator_document') {
        counts.documentSources++;
        return counts;
      }

      counts[`${source.type}Sources`]++;
    }
    return counts;
  }, {
    totalSources: 0,
    statuteSources: 0,
    regulationSources: 0,
    vlex_hmrcSources: 0,
    vlex_legislationSources: 0,
    documentSources: 0,
    caseSources: 0,
    camSources: 0,
    commentarySources: 0,
    unknownSources: 0
  });
}

export function answerGenerationEnded(track: EventTrackFn) {
  return function(
    {
      threadId,
      chatId,
      answerStrategy,
      elapsedTime,
      characterCount,
      reason,
      sources
    }: AnswerGenerationEndedProps
  ) {
    track({
      action: 'answerGenerationEnded',
      properties: {
        threadId,
        chatId,
        answerStrategy,
        elapsedTime,
        characterCount,
        reason,
        ...getSourcesCounts(sources)
      }
    })
  }
}

type AnswerLinkClickedProps = {
  referred: string;
  sourceType: string;
};

export function answerLinkClicked(track: EventTrackFn, context: ChatContext) {
  return function({ referred, sourceType } : AnswerLinkClickedProps) {
    track({
      action: 'answerLinkClicked',
      properties: {
        ...context,
        referred,
        sourceType
      }
    })
  }
}

type AnswerContentCopiedProps = {
  sourcesIncluded: boolean;
};

export function answerContentCopied(track: EventTrackFn, context: ChatContext) {
  return function({ sourcesIncluded }: AnswerContentCopiedProps) {
    track({
      action: 'answerContentCopied',
      properties: {
        ...context,
        sourcesIncluded
      }
    })
  }
}

export function dataSourceEditClicked(track: EventTrackFn) {
  return function() {
    track({
      action: 'dataSourceEditClicked'
    })
  }
}

export function dataSourceEditCancelled(track: EventTrackFn) {
  return function() {
    track({
      action: 'dataSourceEditCancelled'
    })
  }
}

export function dataSourceEditSaved(track: EventTrackFn) {
  return function(newDataSource: string, oldDataSource: string, sourceChanged: boolean) {
    track({
      action: 'dataSourceEditSaved',
      properties: {
        newDataSource,
        oldDataSource,
        sourceChanged
      }
    })
  }
}

export function followUpQuestionsSettingChanged(track: EventTrackFn) {
  return function(option: boolean) {
    track({
      action: 'followUpQuestionsSettingChanged',
      properties: {
        toggle: option ? 'on' : 'off'
      }
    })
  }
}

const askDocInteractionContextMap: Record<AskDocInteractionType, string> = {
  [AskDocInteractionType.PANE]: 'documentOverlay',
  [AskDocInteractionType.PAGE]: 'documentBody',
  [AskDocInteractionType.QUICK_SUMMARIZE]: 'summarizeButton'
};

type DocQuestionCopiedProps = {
  interactionContext: AskDocInteractionType;
  questionCharacterCount: number;
  responseCharacterCount: number;
  sourceType: SourceView['type'];
  sourceTitle: string;
  sourceId: string;
  additionalSources?: boolean;
};

export function docQuestionCopied(track: EventTrackFn) {
  return function(
    {
      interactionContext,
      questionCharacterCount,
      responseCharacterCount,
      sourceType,
      sourceTitle,
      sourceId,
      additionalSources
    }: DocQuestionCopiedProps
  ) {
    track({
      action: 'docQuestionCopied',
      properties: {
        interactionContext: askDocInteractionContextMap[interactionContext],
        sourceType,
        sourceTitle,
        sourceId,
        questionCharacterCount,
        responseCharacterCount,
        additionalSources
      }
    })
  }
}

type DocQuestionAskedProps = Omit<DocQuestionCopiedProps, 'responseCharacterCount'>;

export function docQuestionAsked(track: EventTrackFn) {
  return function(
    {
      interactionContext,
      questionCharacterCount,
      sourceType,
      sourceTitle,
      sourceId,
      additionalSources
    }: DocQuestionAskedProps
  ) {
    track({
      action: 'docQuestionSent',
      properties: {
        interactionContext: askDocInteractionContextMap[interactionContext],
        questionCharacterCount,
        sourceType,
        sourceTitle,
        sourceId,
        additionalSources
      }
    })
  }
}

export function docQuestionRetryClicked(track: EventTrackFn) {
  return function(
    {
      interactionContext,
      questionCharacterCount,
      sourceType,
      sourceTitle,
      sourceId,
      additionalSources
    }: DocQuestionAskedProps
  ) {
    track({
      action: 'docQuestionRetryClicked',
      properties: {
        interactionContext: askDocInteractionContextMap[interactionContext],
        questionCharacterCount,
        sourceType,
        sourceTitle,
        sourceId,
        additionalSources
      }
    })
  }
}

export function docQuestionUnableToAnswerReceived(track: EventTrackFn) {
  return function (
    {
      interactionContext,
      questionCharacterCount,
      sourceType,
      sourceTitle,
      sourceId,
      additionalSources
    }: DocQuestionAskedProps
  ) {
    track({
      action: 'docQuestionUnableToAnswerReceived',
      properties: {
        interactionContext: askDocInteractionContextMap[interactionContext],
        questionCharacterCount,
        sourceType,
        sourceTitle,
        sourceId,
        additionalSources
      }
    })
  }
}

export function settingsClicked(track: EventTrackFn) {
  return function() {
    track({
      action: 'settingsClicked'
    })
  }
}

type ExpectationSettingChangedProps = {
  messageType: string;
  messageText: string;
};

export function expectationMessageViewed(track: EventTrackFn, context: ChatContext) {
  return function(props: ExpectationSettingChangedProps) {
    track({
      action: 'expectationMessageViewed',
      properties: {
        ...context,
        ...props
      }
    })
  }
}

export function restrictedSourceOpenInNewTabClicked(track: EventTrackFn, context: ChatContext) {
  return function({ interactionContext, sourceType, sourceTitle, referredUrl = '', additionalSources }: {
    sourceType: SourceView['badge'],
    sourceTitle: string,
    interactionContext: RestrictedSourceInteractionContext,
    referredUrl?: string,
    additionalSources?: boolean
  }) {
    track({
      action: 'restrictedSourceOpenInNewTabClicked',
      properties: {
        ...context,
        sourceType,
        sourceTitle,
        interactionContext,
        referredUrl,
        additionalSources
      }
    })
  }
}

type CreateMemoEmailProps = {
  questionPairsInThread: number;
};

export function createMemoEmailClickedEvent(track: EventTrackFn, action: 'createMemoClicked' | 'createEmailClicked', context: ThreadContext) {
  return function({ questionPairsInThread }: CreateMemoEmailProps) {
    track({
      action,
      properties: {
        ...context,
        questionPairsInThread
      }
    })
  }
}

export function threadPrintClicked(track: EventTrackFn) {
  return function(threadId: string, questionCount: number) {
    return track({
      action: 'threadPrintClicked',
      properties: {
        threadId,
        questionCount
      }
    });
  }
}

export function tipsExampleClicked(track: EventTrackFn) {
  return function(cardTitle: string) {
    return track({
      action: 'tipsExampleClicked',
      properties: {
        cardTitle
      }
    });
  }
}

type tipsNavProps = {
  fromCard: number;
  fromCardTitle: string;
  toCard: number;
  toCardTitle: string;
};

export function createTipsNavigationClickedEvent(track: EventTrackFn, action: 'tipsNextClicked' | 'tipsBackClicked') {
  return function(props: tipsNavProps) {
    return track({
      action,
      properties: {
        ...props
      }
    });
  }
}


type ThreadGroupToggledProps = {
  open: boolean;
  threadGroupTitle: string
};

export function threadGroupToggled(track: EventTrackFn) {
  return function ({ open, threadGroupTitle }: ThreadGroupToggledProps) {
    track({
      action: open ? 'threadGroupExpanded' : 'threadGroupCollapsed',
      properties: {
        threadGroupTitle
      }
    })
  }
}

export function textLimitWarningViewed(track: EventTrackFn, context: ThreadContext) {
  return function () {
    track({
      action: 'textLimitWarningViewed',
      properties: {
        ...context
      }
    })
  }
}

export function appLoaded(track: EventTrackFn) {
  return function () {
    track({
      action: 'appLoaded'
    })
  }
}

export function viewMoreSourcesClicked(track: EventTrackFn, context: ChatContext) {
  return function ({ sources }: { sources: SourceViewListing[] }) {
    track({
      action: 'viewMoreSourcesClicked',
      properties: {
        ...context,
        ...getSourcesCounts(sources)
      }
    })
  }
}

export function viewLessSourcesClicked(track: EventTrackFn, context: ChatContext) {
  return function () {
    track({
      action: 'viewLessSourcesClicked',
      properties: {
        ...context
      }
    })
  }
}

export function savedPromptCreated(track: EventTrackFn, context: ChatContext) {
  return function ({ question, promptId }: { question: string, promptId: string }) {
    track({
      action: 'savedPromptCreated',
      properties: {
        ...context,
        promptId,
        characterCount: question.length
      }
    })
  }
}

export function savedPromptRemoved(track: EventTrackFn, context: ChatContext) {
  return function ({ question, promptId, interactionContext }: { question: string, promptId: string, interactionContext: string }) {
    track({
      action: 'savedPromptRemoved',
      properties: {
        ...context,
        promptId,
        interactionContext,
        characterCount: question.length
      }
    })
  }
}

export function savedPromptsViewed(track: EventTrackFn) {
  return function() {
    track({
      action: 'savedPromptsViewed'
    })
  }
}

export function savedPromptSelected(track: EventTrackFn) {
  return function ({ question, promptId, interactionContext }: { question: string, promptId: string, interactionContext: string }) {
    track({
      action: 'savedPromptSelected',
      properties: {
        promptId,
        interactionContext,
        characterCount: question.length
      }
    })
  }
}
