import clsx from 'clsx';
import React, { PropsWithChildren, useCallback } from 'react';

import BlueJ from '../../../assets/svg/bluej.svg?react'
import { AdditionalSources } from '../../additional-sources/additional-sources';
import { isNoAnswer } from '../../ask-bluej';
import { useAnalyticsEvent } from '../../core/analytics/useAnalyticsEvent';
import { ChatContextProvider, useChatContext } from '../contexts/ChatContext';
import { GlobalAnalyticsContextProvider } from '../contexts/GlobalAnalyticsContext';
import { useThreadContext } from '../contexts/ThreadContext';
import { useChatQuery } from '../useChatQuery';

import { Question } from './active-question-view/question';
import { AnswerMarkdown } from './answer-markdown';
import { DeleteChat } from './delete-chat';
import { Feedback } from './feedback/feedback';
import { InlineCitationLink } from './inline-citations/inline-citation-link';
import { replaceInlineCitationsWithLinks } from './inline-citations/replace-inline-citations';
import { QuestionViewContainer } from './question-view-container';
import { Share } from './share';
import { SourceList } from './sources/source';
import { SourcesProvider } from './sources/sources-provider';
import { UnableToAnswer } from './unable-to-answer';
import { SavePromptButton } from '../../saved-prompts/save-prompt-button';

interface AnsweredQuestionProps {
  chatId: string;
}

function AbortedAnswer() {
  return (
    <>
      Answer generation stopped.
    </>
  );
}

function UnableToAnswerNoTax() {
  return (
    <>
      Unfortunately I am only able to answer tax questions. If this is a tax question and I have misunderstood, please try rephrasing it.
    </>
  );
}

function AnswerText() {
  const { unanswered, chat } = useChatContext();

  if (unanswered && (chat.chat_type === 'aborted')) {
    return (
      <AbortedAnswer />
    );
  }

  if (chat.chat_type === 'summary_noanswer_notax') {
    return (
      <UnableToAnswerNoTax />
    );
  }

  if (chat.chat_type === 'summary_noanswer' || unanswered) {
    return (
      <UnableToAnswer withSources={chat.sources.length > 0} />
    );
  }
  return (
    <SourcesProvider sources={chat.sources}>
      <AnswerMarkdown answer={replaceInlineCitationsWithLinks(chat.answer, chat.sources)} renderInlineCitation={InlineCitationLink(false)} />
    </SourcesProvider>
  );
}


function Answer() {
  const { chat, shareVisible, feedbackVisible } = useChatContext();

  const isAbortedWithNoAnswer = chat.chat_type === 'aborted' && isNoAnswer(chat.answer);

  const sharedAnswerWrapperClasses = clsx('text-black-400 flex flex-row lg:gap-6 w-full', {
    'items-center': chat.chat_type === 'aborted' && isNoAnswer(chat.answer)
  });

  const fetchingChatAnswerClasses = clsx('flex-row gap-6 w-full max-w-5xl lg:flex', {
    'flex': isAbortedWithNoAnswer
  });

  const fetchingAnswerMarginClasses = clsx({
    'mb-4': !isAbortedWithNoAnswer,
    'mb-0': isAbortedWithNoAnswer
  })

  return (
    <QuestionViewContainer questionComponent={(
      <>
        <Question question={chat.question} strategy={chat.meta?.answerStrategyName} />
        <SavePromptButton />
        <DeleteChat />
      </>
    )}>
      <div className={sharedAnswerWrapperClasses}>
        <div className={fetchingChatAnswerClasses}>
          <div className={clsx(fetchingAnswerMarginClasses, 'flex justify-between items-center lg:block')}>
            <div className="bg-blue-600 rounded-full w-9 h-9 md:w-12 md:h-12 text-grey-600 flex justify-center items-center relative">
              <div className="w-9 h-9 md:w-12 md:h-12 z-10 bg-blue-600 rounded-full">
                <BlueJ />
              </div>
            </div>
            <div className="flex lg:hidden">
              <Share />
            </div>
          </div>
          <div className="w-full h-full self-center">
            <CopyAnswerContainer>
              <AnswerText />
            </CopyAnswerContainer>
            { (shareVisible || feedbackVisible) &&  (
              <div className="flex justify-between items-center mt-4">
                <Feedback />
                <div className="hidden lg:flex">
                  <Share />
                </div>
              </div>
            )}
            <SourceList />
            <AdditionalSources />
          </div>
        </div>
      </div>
    </QuestionViewContainer>
  )
}

function CopyAnswerContainer({ children }: PropsWithChildren) {
  const { trackAnswerContentCopied } = useAnalyticsEvent();
  const { chat } = useChatContext();

  const onCopyHandler = useCallback(() => {
    if (!chat.chat_id) {
      return;
    }
    const copiedContent = document.getSelection()?.getRangeAt(0).cloneContents();
    if (!copiedContent || !copiedContent.textContent) {
      return;
    }
    let sourcesCopied = false;
    const sources = copiedContent.querySelector(`#sources-list-${chat.chat_id}`);
    if (sources !== null && sources.textContent?.length) {
      sourcesCopied = true;
    }
    trackAnswerContentCopied({ sourcesIncluded: sourcesCopied });
  }, [chat.chat_id]);

  return (
    <div className="flex-1 self-center" onCopy={onCopyHandler}>
      { children }
    </div>
  )
}

export function AnsweredQuestion({ chatId }: AnsweredQuestionProps) {
  const { thread } = useThreadContext();
  const { status, data } = useChatQuery(thread.id, chatId);

  if (status === 'error') {
    return <div>Error :(</div>;
  }

  if (data === undefined) {
    return null;
  }

  const chatContextValue = {
    chat: data
  };

  const answerStrategy = data.meta?.answerStrategyName || '';

  return (
    <GlobalAnalyticsContextProvider value={{ chatId, answerStrategy }}>
      <ChatContextProvider value={chatContextValue}>
        <Answer />
      </ChatContextProvider>
    </GlobalAnalyticsContextProvider>
  )
}
