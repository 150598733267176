import {
  Chat,
  Country,
  CreateThreadPayload,
  NPSStatusResponse,
  Preferences,
  StrategyResult,
  Thread,
  UpdateThreadPayload,
  User,
  UserPreferences
} from '@/types';
import React from 'react';

import { createUseContext } from '../../../utils/create-use-context';
import { FeedbackPayload } from '../../thread/types';
import { TSpanEvent } from '../../trace/types';
import { AuthGuardApi } from './api';

export interface IAskBlueJApi {
  getChat: (threadId: string, chatId: string) => Promise<Chat>;
  abortChat: (threadId: string, chatId: string) => Promise<Chat>;
  createThread: (topic: string) => Promise<Thread>;
  getThread: (threadId: string, page: number, perPage: number) => Promise<Thread>;
  getThreads: () => Promise<Thread[]>;
  updateThread: (threadId: string, title: string) => Promise<Thread>;
  deleteThread: (threadId: string) => Promise<void>;
  sendFeedback: (data: FeedbackPayload) => Promise<void>;
  generateThreadTitle: (threadId: string, chatId: string) => Promise<Thread>;
  recommendFollowUp: (threadId: string, question: string, answer: string, variant: Country) => Promise<string[]>;
  whoami: () => Promise<User>;
  generateAuthLink: (apiUrl: string, returnToUrl: string, action: 'login' | 'logout') => string;
  deleteChat: (threadId: string, chatId: string) => Promise<void>;
  getStrategies: () => Promise<StrategyResult>;
  getUserNPSStatus: () => Promise<NPSStatusResponse>;
  getUserPreferences: () => Promise<UserPreferences>;
  updateUserPreferences: (preferences: Preferences) => Promise<UserPreferences>;
  getTrace: (chatId: string) => Promise<TSpanEvent[]>;
  expectation: (question: string, strategy: string) => Promise<{ criteria: string; }>;
  createSavedPrompt: (savedPromptId: string, question: string) => Promise<{ savedPromptId: string }>;
  deleteSavedPrompt: (savedPromptId: string) => Promise<void>;
  getSavedPrompts: () => Promise<{ savedPromptId: string; question: string }[]>;
}

/**
 * This API is specifically for requests to the AskBlueJ Node Backend,
 * If you're requesting from other downstream sources (Bookcase, Curator, etc.) -- consider using the SourcesAPI or
 * making another similar class.
 */
export class AskBlueJApi extends AuthGuardApi implements IAskBlueJApi {
  private basePath = 'api';

  private _getChat = this.createFetch<null, Chat, { threadId: string, chatId: string }>(params => `threads/${params.threadId}/chats/${params.chatId}`, 'GET');
  private _abortChat = this.createFetch<{ threadId: string, chatId: string }, Chat, void>(`${this.basePath}/abort-chat`, 'POST');
  private _getThread = this.createFetch<null, Thread, { threadId: string, page: number, perPage: number }>(params => {
    const { threadId, page, perPage } = params;
    return `threads/${threadId}?page=${page}&perPage=${perPage}`;
  }, 'GET');
  private _createThread = this.createFetch<CreateThreadPayload, Thread, void>(`${this.basePath}/create-thread`, 'POST');
  private _getThreads = this.createFetch<null, Thread[], void>(`${this.basePath}/all-threads`, 'GET');
  private _deleteThread = this.createFetch<{ threadId: string }, Thread, void>(`${this.basePath}/delete-thread`, 'POST');
  private _sendFeedback = this.createFetch<FeedbackPayload, void, void>(`${this.basePath}/feedback`, 'POST');
  private _updateThread = this.createFetch<UpdateThreadPayload, Thread, void>(`${this.basePath}/update-thread`, 'POST');
  private _generateThreadTitle = this.createFetch<{ threadId: string, chatId: string }, Thread, void>(`${this.basePath}/generate-thread-title`, 'POST');
  private _recommendFollowUp = this.createFetch<{ threadId: string, question: string, answer: string, variant: Country }, string[], void>(`${this.basePath}/recommend-followup`, 'POST');
  private _whoami = this.createFetch<null, User, void>(`${this.basePath}/whoami`, 'POST');
  private _deleteChat = this.createFetch<{threadId: string, chatId: string }, Chat, void>(`${this.basePath}/delete-chat`, 'POST');
  private _getStrategies = this.createFetch<null, StrategyResult, null>(`${this.basePath}/manage/strategies`, 'GET');
  private _getUserNPSStatus = this.createFetch<null, NPSStatusResponse, null>('nps', 'GET');
  private _getUserPreferences = this.createFetch<null, UserPreferences, null>(`${this.basePath}/get-user-preferences`, 'GET');
  private _updateUserPreferences = this.createFetch<Preferences, UserPreferences, void>(`${this.basePath}/update-user-preferences`, 'POST');
  private _getTrace = this.createFetch<null, TSpanEvent[], { chatId: string }>(params => `${this.basePath}/chat/${params.chatId}/trace`, 'GET');
  private _expectation = this.createFetch<{ question: string, strategy: string }, { criteria: string }, void>(`${this.basePath}/expectation`, 'POST');
  private _createSavedPrompt = this.createFetch<{ savedPromptId: string, question: string }, { savedPromptId: string }, void>(`${this.basePath}/create-saved-prompt`, 'POST');
  private _deleteSavedPrompt = this.createFetch<{ savedPromptId: string }, void, void>(`${this.basePath}/delete-saved-prompt`, 'POST');
  private _getSavedPrompts = this.createFetch<null, { savedPromptId: string, question: string }[], void>(`${this.basePath}/get-saved-prompts`, 'GET');

  public async getChat(threadId: string, chatId: string) {
    return this._getChat(null, { threadId, chatId });
  }

  public async abortChat(threadId: string, chatId: string) {
    return this._abortChat({ threadId, chatId });
  }

  public async createThread(topic: string) {
    return this._createThread({ topic });
  }

  public async getThread(threadId: string, page: number, perPage: number) {
    return this._getThread(null, { threadId, page, perPage });
  }

  public async getThreads() {
    return this._getThreads();
  }

  public async updateThread(threadId: string, title: string) {
    return this._updateThread({ threadId, title, updateDate: false });
  }

  public async deleteThread(threadId: string) {
    await this._deleteThread({ threadId }).catch(() => {});
  }

  public async sendFeedback(data: FeedbackPayload) {
    return this._sendFeedback(data);
  }

  public async generateThreadTitle(threadId: string, chatId: string) {
    return this._generateThreadTitle({ threadId, chatId });
  }
  public async whoami() {
    return this._whoami();
  }

  public async getStrategies() {
    return this._getStrategies();
  }

  public async deleteChat(threadId: string, chatId: string) {
    await this._deleteChat({ threadId, chatId }).catch(() => {});
  }

  public async getUserNPSStatus() {
    return this._getUserNPSStatus();
  }

  public async getUserPreferences() {
    return this._getUserPreferences();
  }

  public async updateUserPreferences(preferences: Preferences) {
    return this._updateUserPreferences(preferences);
  }

  public async getTrace(chatId: string) {
    return this._getTrace(null, { chatId });
  }

  public async recommendFollowUp(threadId: string, question: string, answer: string, variant: Country) {
    return this._recommendFollowUp({ threadId, question, answer, variant });
  }

  public async expectation(question: string, strategy: string) {
    return this._expectation({ question, strategy });
  }

  public async createSavedPrompt(savedPromptId: string, question: string) {
    return this._createSavedPrompt({ savedPromptId, question });
  }

  public async deleteSavedPrompt(savedPromptId: string) {
    return this._deleteSavedPrompt({ savedPromptId });
  }

  public async getSavedPrompts() {
    return this._getSavedPrompts();
  }
}

const AskBluejApiContext = React.createContext<{ api: IAskBlueJApi } | null>(null);
AskBluejApiContext.displayName = 'AskBluejApiContext';
export const useAskBluejApiContext = createUseContext(AskBluejApiContext);

export const AskBlueJApiContextProvider = AskBluejApiContext.Provider;
