import { StarIcon } from '@heroicons/react/24/outline';
import React, { useCallback, useEffect, useState } from 'react';

import { useSavedPromptCreateQuery } from '../thread/useSavedPromptCreateQuery';
import { useSavedPromptDeleteQueryWithChat } from '../thread/useSavedPromptDeleteQuery';
import { withFlag } from '../../experiments/wrap-experiment';
import { autoUpdate, useFloating, flip, shift, offset } from '@floating-ui/react';

const SavedPromptComponent = () => {
  const [isSaved, setIsSaved] = useState(false);
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const createSavedPrompt = useSavedPromptCreateQuery();
  const deleteSavedPrompt = useSavedPromptDeleteQueryWithChat();

  const { refs, floatingStyles } = useFloating({
    open: isTooltipVisible,
    onOpenChange: setTooltipVisible,
    whileElementsMounted: autoUpdate,
    middleware: [
      flip({ fallbackPlacements: ['top', 'bottom'] }),
      shift(),
      offset(5)
    ],
    placement: 'top'
  });

  useEffect(() => {
    if (isTooltipVisible) {
      setTimeout(() => {
        setTooltipVisible(false);
      }, 1500);
    }
  }, [isTooltipVisible]);

  const handleCreate = useCallback(async () => {
    await createSavedPrompt.mutateAsync();
    setIsSaved(true);
    setTooltipVisible(true);
  }, []);

  const handleDelete = useCallback(async () => {
    await deleteSavedPrompt.mutateAsync();
    setIsSaved(false);
  }, []);

  return (
    <div className="hidden lg:flex flex-row self-center gap-1 lg:gap-0 w-12 align-right">
      {
        (!isSaved)
          ? <div className="text-grey-600 hover:text-blue-400 items-center" role="button" onClick={handleCreate}>
            <StarIcon className="w-6 h-6 lg:w-24 lg:h-5 inline-block"/>
          </div>
          : <div className="text-blue-400 items-center" role="button" onClick={handleDelete}>
            <StarIcon fill={"#90c0fc"} className="w-6 h-6 lg:w-24 lg:h-5 inline-block"/>
            {isTooltipVisible && (
              <div className="relative">
                <div
                  ref={refs.setFloating}
                  style={floatingStyles}
                  className="select-none whitespace-nowrap tooltip bg-grey-600 text-white px-2 py-1 text-xs rounded-md"
                  onCopy={(e) => { e.preventDefault(); return false; }}
                  role="tooltip"
                  title={'Prompt saved'}
                >
                  Prompt saved
                </div>
              </div>
            )}
          </div>
      }
    </div>
  )
}

export const SavePromptButton = withFlag(SavedPromptComponent, 'saved-prompts');
